import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { useNavigate } from "react-router-dom";
import logo2 from '../../assets/images/IOS logo 2.png';
import logo3 from '../../assets/images/ISO logo 3.jpg';
import logo1 from '../../assets/images/ISO logo.png';
import CarouselFade from "../../Components/Carousel";
import "./index.css";
// const Home = ({t, login, setlogin, setgolobalKeyword,searchKeyword,setSearchKeyword}) => {
const Home = ({ isAuthenticated }) => {
  const [sliders, setSliders] = useState([]);
  const [about, setabout] = useState(false)

  useEffect(() => {
 
    const url = "https://prostuti-backend.techknowgram.net/api/react/sliders";

    axios
      .get(url)
      .then(function (response) {
        console.log(response);
        const { data } = response;
        setSliders(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  console.log("sliders", sliders);
  const navigate = useNavigate();

  const goToEvents = (e) => {
    e.preventDefault();
    navigate("/events");
  };

  const goToRule = (e) => {
    e.preventDefault();
    navigate("/rule");
  };

  const goToFaq = (e) => {
    e.preventDefault();
    navigate("/faq");
  };

  const goToPrizes = (e) => {
    e.preventDefault();
    navigate("/prize");
  };


  const goToRegister = (e) => {
    e.preventDefault();
    navigate("/user-register");
  };

  const goToLogin = (e) => {
    e.preventDefault();
    navigate("/user-login");
  };

  const goToProfile = (e) => {
    e.preventDefault();
    navigate("/profile");
  };

  const goToLogout = (e) => {
    e.preventDefault();
    navigate("/logout");
  };

  const goToResult = (e) => {
    e.preventDefault();
    navigate("/result");
  }

  const goToabout = (e) => {
    e.preventDefault();
    setabout(!about)
  }

  let authUrls = (
    <>
      <a className="regOnlyOnMobile" onClick={goToRegister} href="">
        রেজিস্ট্রেশন
      </a>
      <a className="regOnlyOnMobile" onClick={goToLogin} href="">
        লগইন
      </a>
    </>
  );

  if (isAuthenticated) {
    authUrls = (
      <>
        <a className="regOnlyOnMobile" onClick={goToProfile} href="">
          প্রোফাইল
        </a>
        <a className="regOnlyOnMobile" onClick={goToLogout} href="">
          লগআউট
        </a>
      </>
    );
  }

  return (
    <>
      <section id="banner">
        <div class="overlay">
        </div>
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6">
              <div class="left">
                <h1>প্রস্তুতিতে প্রস্তুত হোন !</h1>
                <a onClick={goToEvents} href="">
                  অংশগ্রহণ করুন
                </a>

                <div className="d-flex d-md-none justify-content-between align-items-center">
                  {authUrls}
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="banner_content">
                <div class="img">
                
                  <img
                    src="https://prostuti.net/admin/media/homepage/homepage-63a43cd58a728.png"
                    alt=""
                  />
                
                  <div class="overlay_img">
                 
                  </div>
                </div>
                <div class="text">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container showOnlyOnMobile">
          <div class="d-flex justify-content-center w-100">

            <div class="w-50 mx-2 p-1  border border-primary rounded">
              <a onClick={goToResult} class="d_flex text-center" href="javascript:;">
                ফলাফল{" "}
              </a>{" "}
            </div>
            <div class="w-50 mx-2 p-1  border border-primary rounded">
              <a onClick={goToPrizes} class="d_flex text-center" href="javascript:;">
                পুরস্কার লিস্ট{" "}
              </a>{" "}
            </div>
          </div>
        </div>
      </section>

      <section id="banner2">
        <div class="container py-5">
          <div class="row">
            <div class="col-md-10 col-lg-12 mx-auto">
              {sliders.length ? (
                <CarouselFade sliders={sliders} />
              ) : (
                <h1>Sliders Loading...</h1>
              )}
            </div>
          </div>
        </div>

        <div class="container-fluid p-0 footerOnMobile">

          <div class="col-lg-3 col-md-3">
            <div class="footer_content">
              <h3 style={{ marginLeft: "1em", marginTop: "2em" }}>Pages</h3>
              <div
                class="list-none"
                style={{ marginLeft: "2em", marginBottom: "2em" }}
              >
                <h5>
                  <a onClick={goToRule} href="javascript:;">
                    নিয়মাবলি
                  </a>
                </h5>
                <h5>
                  <a onClick={goToEvents} href="">
                    সকল কুইজ
                  </a>
                </h5>              
                <h5>
                  <a onClick={goToFaq} href="">
                    FAQ
                  </a>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3" style={{ marginLeft: "2em" }}>
            <h4 class="mb-2">Follow Us On:</h4>
            <div class="social mb-5">
              <a href="https://www.facebook.com/prostuti.net" target="_blank" rel="noreferrer"><i class="fab fa-facebook-square" style={{ fontSize: "30px", color: "#1877F2", marginRight: "10px" }} ></i></a>
              <a href="https://twitter.com/prostuti_net" target="_blank" rel="noreferrer"><i class="fab fa-twitter" style={{ fontSize: "30px", color: "#1D9BF0", marginRight: "10px" }}></i></a>
              <a href="https://www.youtube.com" target="_blank" rel="noreferrer"><i class="fab fa-instagram" style={{ fontSize: "30px", color: "#FF0000", marginRight: "10px" }}></i></a>
              <a href="https://bd.linkedin.com/in/prostuti-net-b040b9266/" target="_blank" rel="noreferrer"><i class="fab fa-linkedin" style={{ fontSize: "30px", color: "#0A66C2", marginRight: "10px" }}></i></a>
            </div>
          </div>
          <div className="col-lg-3 col-md-3" style={{ marginLeft: "2em", marginBottom: "2em" }}>
            <h3 className="about">About us</h3>
            <span >  Prostuti is a product of TechKnowGram Limited.TechKnowGram Limited is a leading technology company based in Bangladesh that specializes in providing web-based customized solutions in various areas, including ERP, AI, Data Analytics, Robotics, Embedded Solutions with Beacon, AR, VR <span>{!about && "..."}</span>{about && <span>Applications, Gaming, and Animation. The company is globally recognized for its expertise in the Japan ICT market, where it has been operating for over 20 years. Additionally, TechKnowGram Limited also has a strong presence in the USA and Europe markets. With over 25 years of combined professional experience, the company's team of experts is dedicated to delivering innovative solutions to its clients. TechKnowGram Limited is proud to be an ISO 9001: 2015, ISO 14001: 2015, and ISO/IEC 27001: 2013 certified company and a member of several reputable organizations, including Bangladesh Association of Software and Information Services BASIS, Bangladesh and Japan Chamber of Commerce & Industries JBCCI, Bangladesh Computer Samity BCS, Bangladesh Association of Call Centers and Outsourcing BACCO, Ecommerce Association of Bangladesh e-CAB, CTO Forum of Bangladesh, and SheTrades Commonwealth Program by International Trade Centre ITC.</span>}</span><span onClick={goToabout} style={{color: "blue", cursor: "pointer"}}> {about ? "Read Less":"Read more" }</span>
            <div className=""> 
          <img  style={{height:"55px", width:"55px",margin:"10px",borderRadius:"10px"}} src={logo2} alt="" />
          <img style={{height:"55px", width:"65px",margin:"10px",}} src={logo1} alt="" />
          <img style={{height:"55px", width:"55px",margin:"10px",borderRadius:"10px"}} src={logo3} alt=""  />
          </div>
          </div>
        </div>
        {/* <div class="row footerOnMobile">
          <div id="footer-bottom" class="clr no-footer-nav p-3">
            <div id="footer-bottom-inner" class="container clr">
              <div id="copyright" class="clr" role="contentinfo">
                <div class="d-flex justify-content-center align-items-center">
                  © All Rights Reserved &nbsp;& Powered By TechKnowGram Limited
                  <a href="https://www.techknowgram.com" target="_Blank" rel="noreferrer">
                    {" "}
                    <img
                      src={require("../../images/tkgl_logo.png")}
                      width="100"
                      height="35"
                      class="lazyloaded"
                      data-ll-status="loaded"
                    />
                  </a>
                </div>              
              </div>       
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);