import React, { useEffect } from 'react';
import Footer from './Footer';
import Header from './Header';



const Layout = ({children, changeLn, t, login, setlogin}) => {

  useEffect(() => {
    console.log("from layout", login);
  }, [login])
  


  return (
    <>
        <Header />
            {children}
        <Footer />
         <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">ADDRESS</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <ul>
                    <li> TechKnowGram Limited:</li>
                    <li> 5/9 Block B, Suite B1 Lalmatia Dhaka 1207. </li>
                    <li> Info@TechKnowGram.com. </li>
                    <li> Cell: +8801819250309 </li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Layout