import Carousel from "react-bootstrap/Carousel";

function CarouselFade({ sliders }) {
  return (
    <Carousel fade>
      {sliders.map((slider) => (
        <Carousel.Item>
          <img
            className="d-block w-100 caro-img"
            src={"http://prostuti.net/admin/" + slider.value}
            alt="First slide"
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default CarouselFade;
