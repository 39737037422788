import axios from "axios";
import { Formik } from "formik";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { authOperations } from "../../state/ducs/auth";
import { authHeader } from "../../utils";
import "./index.css";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Edit = ({ userInfo, checkUser }) => {
  const phoneRegExp = /^(?:\+?88)?01[13-9]\d{8}$/; //bd phone regex

  const registrationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    username: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    mobile_number: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid"
    ),
  });

  const handleRegistrationSubmit = (values, actions) => {
    //network request here
    const regPostObj = {
      name: values.name,
      username: values.username,
      email: values.email,
      mobile_number: values.mobile_number,
    };
    
    const Url =
      "https://prostuti-backend.techknowgram.net/api/react/user/updateUser";

    axios
      .patch(Url, regPostObj, { headers: authHeader() })
      .then((response) => {
        if (response.status === 200) {
          actions.resetForm();
          // alert("Registration successful");
          checkUser();
          MySwal.fire(<p>Update successful</p>);
          navigate("/Profile");
        }
      })
      .catch((error) => {
        if (error.response.data.message.length) {
          return MySwal.fire(<p>{error.response.data.message}</p>);
        }
      });
  };

  const navigate = useNavigate();

  const goToProfile = (e) => {
    e.preventDefault();
    navigate("/profile");
  };
  return (
    <>
      <section id="login">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-8 m-auto">
              <Formik
                initialValues={{
                  name: userInfo.name,
                  username: userInfo.username,
                  email: userInfo.email,
                  mobile_number: userInfo.mobile_number,
                }}
                validationSchema={registrationSchema}
                onSubmit={handleRegistrationSubmit}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* @csrf */}

                    <div class="login_coltent">
                      <div class="img">
                        <img
                          src="https://prostuti.net/admin/media/logo/logo-63a43964406bc.png"
                          alt=""
                        />
                      </div>

                      <h2>Update Profile</h2>

                      <div class="custome_input">
                        <input
                          type="text"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          required
                          placeholder="আপনার নাম"
                        />

                        {touched.name && errors.name && (
                          <div style={{ color: "red" }}>{errors.name}</div>
                        )}
                      </div>

                      <div class="custome_input">
                        <input
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          required
                          placeholder="আপনার ইমেইল"
                        />
                        {touched.email && errors.email && (
                          <div style={{ color: "red" }}>{errors.email}</div>
                        )}
                      </div>

                      <div class="custome_input">
                        <input
                          type="text"
                          name="mobile_number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.mobile_number}
                          required
                          placeholder="আপনার মোবাইল নম্বর"
                        />

                        {touched.mobile_number && errors.mobile_number && (
                          <div style={{ color: "red" }}>
                            {errors.mobile_number}
                          </div>
                        )}
                      </div>

                      <div class="custome_input">
                        <input
                          type="text"
                          name="username"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.username}
                          placeholder="আপনার ইউজার নাম প্রবেশ করুন"
                          required
                        />

                        {touched.username && errors.username && (
                          <div style={{ color: "red" }}>{errors.username}</div>
                        )}
                      </div>

                      <div class="custome_input">
                        <button type="submit"> SUBMIT </button>
                        <h3>
                          <a onClick={goToProfile} href="#">
                            Profile
                          </a>
                        </h3>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = {
  checkUser: authOperations.authUserCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);

// export default Edit;
