import axios from "axios";
import React, { useEffect, useState } from "react";
import "./index.css";

import { useNavigate } from "react-router-dom";

import { authHeader } from "../../utils";

const Result = () => {
  const [events, setEvents] = useState([]);

  const [winners, setWinners] = useState([]);

  const [event, setEvent] = useState({});

  const [isFetching, setIsFetching] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const url = "https://prostuti-backend.techknowgram.net/api/react/events";

    axios
      .get(url, { headers: authHeader() })
      .then(function (response) {
        console.log(response);
        const { data } = response;
        setEvents(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleChange = (e) => {
    console.log(e.target);

    const eventId = e.target.value.split("/")[0];

    const event = events.find((event) => event.id == eventId);

    setEvent(event);

    setIsFetching(true);

    const url = "https://prostuti-backend.techknowgram.net/api/react/events/";

    axios
      .get(url + eventId + "/participants", { headers: authHeader() })
      .then(function (response) {
        console.log(response);
        const { data } = response;
        setWinners(data);
        setIsFetching(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {/* <section id="banner" class="Banner_others section_gaps result">
                <div class="container">
                    <div class="row d_flex">
                        <div class="col-lg-6 m-auto">
                            <div class="left">
                                <h1>Tournament Live</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      <section style={{ marginTop: "5rem" }} id="rules" class="section_gaps">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 m-auto">
              <div class="row">
                <div class="col-md-3 m-auto">
                  <form action="{{ route('result') }}" method="GET">
                    <input type="hidden" name="resultshow" value="1" />
                    <label for="">Select Event</label>
                    <select
                      onChange={handleChange}
                      name="event"
                      class="form-control"
                    >
                      <option value="" disabled selected>
                        Select One
                      </option>

                      {events.length &&
                        events.map((ev, i) => {
                          if (ev.event_name == "For App") {
                            return;
                          }
                          return (
                            <option value={ev.id + "/" + (i + 1)}>
                              {ev.event_name}
                            </option>
                          );
                        })}
                    </select>
                  </form>
                </div>
              </div>
              <div class="result_header my-2">
                <h3>
                  {" "}
                  লিডারবোর্ড
                  {/* {winners.length && replaceNumbers(winners.length.toString())} জন  */}
                </h3>
              </div>
            </div>
          </div>
          <div class="result_content">
            <div class="row">
              {winners.length
                ? winners.map((winner, i) => (
                    <div class="col-lg-2 col-md-3 col-sm-6 col-6">
                      <div class="result_item">
                        <div class="img">
                          {/* {{-- < img src="{{ asset('media/resultwinner/man.png') }}" alt=""> --}} */}

                          {winner.profile_photo_path && (
                            <img
                              src={winner.profile_photo_path}
                              alt="..."
                              class="img-thumbnail"
                            />
                          )}
                          {!winner.profile_photo_path && (
                            <i
                              style={{ fontSize: "130px" }}
                              class="fa fa-user-circle"
                            ></i>
                          )}
                        </div>
                        <div class="text">
                          <h3>
                            {(winner.user && winner.user.name) ||
                              (winner.user && winner.user.username)}
                          </h3>
                          <p>Score : {winner.total_mark}</p>
                          <p>Position: {i + 1}</p>
                          <p>Time : {winner.total_time} (seconds)</p>
                        </div>
                      </div>
                    </div>
                  ))
                : ""}
              
              {!winners.length && !isFetching && event.event_name ? (
                <h4 className="text-center">No Results to Show</h4>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Result;
