import axios from "axios";
import React, { useEffect, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";

import { authHeader } from "../../utils";
import "./index.css";

const EventPlay = () => {
  const navigate = useNavigate();

  const [event, setEvent] = useState({});
  const [questions, setQuestions] = useState([]);
  const [index, setIndex] = useState(0);
  const { eventId } = useParams();
  const [counter, setCounter] = useState(0);
  const [answers, setAnswers] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isSubmitting) {
      const timer = setTimeout(() => {
        if (counter <= 0) {
          chooseAnswer();
          return;
        }
        setCounter(counter - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    const url = "https://prostuti-backend.techknowgram.net/api/react/events";

    axios
      .get(url + "/" + eventId, { headers: authHeader() })
      .then(function (response) {
        const { data } = response;
        console.log(data);
        setEvent(data);
        setQuestions(data.questions);
        setCounter(data.event_mark_setup.per_mcq_time_duration);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const chooseAnswer = (e) => {
    // e.target.data('answer');
    const answerChosen = e ? e.target.getAttribute("data-answer") : null;
    console.log("answerChosen", answerChosen);

    const answerObj = {
      question_id: questions[index].id,
      answer: answerChosen,
      time:
        event.event_mark_setup.per_mcq_time_duration - counter == 0
          ? 1
          : event.event_mark_setup.per_mcq_time_duration - counter,
    };

    setAnswers([...answers, answerObj]);

    if (index == questions.length - 1) {
      const lastAnswer = answerObj;
      const postObj = {
        event_id: event.id,
        answers: [...answers, lastAnswer],
      };

      setIsSubmitting(true);

      const url =
        "https://prostuti-backend.techknowgram.net/api/react/submitAnswers";

      axios
        .post(url, postObj, { headers: authHeader() })
        .then(function (response) {
          const { data } = response;
          console.log(data);

          setIsSubmitting(false);

          //navigate to result page here
          navigate("/myResult", {
            state: data,
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setIndex(index + 1);
      setCounter(event.event_mark_setup.per_mcq_time_duration);
    }
  };

  let options = [];
  if (questions.length) {
    for (const key in questions[index].options) {
      if (Object.hasOwnProperty.call(questions[index].options, key)) {
        const element = questions[index].options[key];

        const obj = {
          option_key: key,
          option_value: element,
        };

        options.push(obj);
      }
    }
  }

  console.log("isSubmitting", isSubmitting);

  return (
    <>
      <section id="banner" class="Banner_others section_gaps faq">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>প্রস্তুতিতে প্রস্তুত হোন</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="attend_quiz" class="section_gaps">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6 col-12 m-auto">
              <div class="attend_quiz_item">
                <div class="img">
                  <img
                    class="img-fluid w-sm-50 w-md-100"
                    src="http://prostuti.net/admin/media/logo/logo-635c0e5b31136.png"
                    alt=""
                  />
                </div>
                <div class=" start_quiz">
                  {event.event_name ? (
                    <div class="time">
                      <>
                        <h6 id="count"> {counter}</h6> <span> sec</span>
                      </>
                    </div>
                  ) : (
                    <FallingLines
                      color="#4fa94d"
                      width="100"
                      visible={true}
                      ariaLabel="falling-lines-loading"
                    />
                  )}

                  {questions.length ? (
                    <h4 className="copyRestrict">
                      {" "}
                      {questions[index].question}{" "}
                    </h4>
                  ) : (
                    <FallingLines
                      color="#4fa94d"
                      width="100"
                      visible={true}
                      ariaLabel="falling-lines-loading"
                    />
                  )}

                  <div class="text">
                    <ul>
                      {options.length ? (
                        options.map((option, i) => (
                          <li>
                            <a
                              onClick={chooseAnswer}
                              href="javascript:;"
                              class="option copyRestrict"
                              data-answer={option.option_key}
                            >
                              {i == 0 && <span>ক</span>}
                              {i == 1 && <span>খ</span>}
                              {i == 2 && <span>গ</span>}
                              {i == 3 && <span>ঘ</span>}
                              {option.option_value}
                            </a>
                          </li>
                        ))
                      ) : (
                        <FallingLines
                          color="#4fa94d"
                          width="100"
                          visible={true}
                          ariaLabel="falling-lines-loading"
                        />
                      )}
                    </ul>

                    <div class="quiz_prograss d_flex">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "10%" }}
                          aria-valuenow="10"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>

                      <h5>
                        <span id="totalcount"> {index + 1} </span> /{" "}
                        <span id="questionperlavel">
                          {" "}
                          {event.event_name &&
                            event.event_mark_setup.question_per_level}
                        </span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EventPlay;
