import { Formik } from "formik";
import React from 'react';
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";


const SubmitOtp = () => {

    const navigate= useNavigate();
    const otpSchema = Yup.object().shape({
        // email: Yup.string().email("Invalid email").required("Required"),
        otp: Yup.string().required("Required"),

    });

    const handleOtpSubmit = (values) => {
        // signIn(values.username, values.password);
         //navigate to result page here
         navigate(
            '/password-reset',
            {
                state: values.otp
            }
        )

    };

    const goToLogin=(e)=> {
        e.preventDefault();
        navigate("/user-login");
    }
  return (
    <>
      <section id="login">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-8 m-auto">
              <Formik
                initialValues={{
                  otp: "",
                }}
                validationSchema={otpSchema}
                onSubmit={handleOtpSubmit}
              >
                {({
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* @csrf */}

                    <div class="login_coltent">
                      <div class="img">
                        <img
                          src="https://prostuti.net/admin/media/logo/logo-63a43964406bc.png"
                          alt=""
                        />
                      </div>

                      <h2>Submit OTP</h2>

                      <div class="custome_input">
                        <input
                          type="text"
                          name="otp"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.otp}
                          placeholder="আপনার চার ডিজিট কোড টি প্রবেশ করুন"
                          required
                        />
                        {touched.otp && errors.otp && (
                          <div style={{ color: "red" }}>{errors.otp}</div>
                        )}
                      </div>

                      <div class="custome_input">
                        <button type="submit"> SUBMIT </button>
                        <h3>
                          <a
                            onClick={goToLogin}
                            href="/user-login"
                          >
                            লগইন করুন
                          </a>
                        </h3>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SubmitOtp