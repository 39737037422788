import React from "react";
import "./index.css";

const Timer = ({ counter }) => {
  return (
    <>
        <div className="fixedTimer">
          <div id="time">Time left: {Math.floor(counter / 60)}:{counter - (Math.floor(counter / 60))*60} mins</div>
        </div>
    </>
  );
};

export default Timer;
