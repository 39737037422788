import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import placeholder from "../../assets/images/placeholder.png";

const Myaccount = ({ login, setlogin, isAuthenticated, userInfo }) => {
  const onLoginClick = (event) => {
    event.preventDefault();
    setlogin(true);
  };

  let myAccountLinks = (
    <div className="panel-body">
      <Link to={"/login"} href="" className="list-group-item">
        <i className="fa fa-user" aria-hidden="true"></i>
        Login
      </Link>
      <Link to={"/register"} href="" className="list-group-item">
        <i className="fa fa-key" aria-hidden="true"></i>
        Register
      </Link>
      <Link to={"/forgottenPassword"} href="" className="list-group-item">
        <i className="fa fa-unlock-alt" aria-hidden="true"></i>
        Forgotten Password
      </Link>
      <Link to={"/myAccounts"} href="" className="list-group-item">
        <i className="fa fa-user" aria-hidden="true"></i>
        My Account
      </Link>
      <Link to={"/addressBook"} href="" className="list-group-item">
        <i className="fa fa-address-book" aria-hidden="true"></i>
        Address Book
      </Link>
      <Link to={"/wishList"} href="" className="list-group-item">
        <i className="fa fa-heart" aria-hidden="true"></i>
        Wish List(%s)
      </Link>
      <Link to={"/newslatter"} href="" className="list-group-item">
        <i className="fa fa-envelope" aria-hidden="true"></i>
        Newsletter
      </Link>
    </div>
  );

  let profileSection = null;

  if (isAuthenticated) {
    myAccountLinks = (
      <div className="panel-body">
        <Link to={"/myAccounts"} href="" className="list-group-item">
          <i className="fa fa-user" aria-hidden="true"></i>
          My Account
        </Link>
        <Link to={"/editAccounts"} href="" className="list-group-item">
          <i className="fa fa-user" aria-hidden="true"></i>
          Edit Account
        </Link>
        <Link to={"/changePassword"} href="" className="list-group-item">
          <i className="fa fa-unlock-alt" aria-hidden="true"></i>
          Change Password
        </Link>
        <Link to={"/addressBook"} href="" className="list-group-item">
          <i className="fa fa-address-book" aria-hidden="true"></i>
          Address Book
        </Link>
        <Link to={"/wishList"} href="" className="list-group-item">
          <i className="fa fa-heart" aria-hidden="true"></i>
          Wish List(%s)
        </Link>
        <Link to={"/newslatter"} href="" className="list-group-item">
          <i className="fa fa-envelope" aria-hidden="true"></i>
          Newsletter
        </Link>
      </div>
    );

    profileSection = (
      <div className="panel panel-default profile--panel profileSectionContainer">
        <div className="panel-heading d-flex flex-column justify-content-center align-items-center">
          <Link to={"/editProfile"}>
            <img
              height={100}
              width={100}
              className="rounded-circle border border-secondary text-center"
              style={{ background: "#fff" }}
              src={placeholder}
              alt=""
            />
          </Link>
          <h6 className="mt-3">{userInfo.name}</h6>
          <p>{userInfo.email}</p>
          <ul class="list-inline">
            <li>
              <Link
                to="/edit"
                data-toggle="tooltip"
                title=""
                data-original-title="Edit Profile"
              >
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>Edit
                Profile
              </Link>
            </li>
            <li>
              <Link
                to="/logout"
                data-toggle="tooltip"
                title=""
                data-original-title="Logout"
              >
                <i class="fa fa-sign-out" aria-hidden="true"></i>Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className="col-md-3">
      {profileSection}
      <div className="panel panel-default profile--panel">
        <div className="panel-heading">My Account</div>
        {myAccountLinks}
      </div>
      <div className="panel panel-default profile--panel">
        <div className="panel-heading">CHECK ORDER</div>
        <div className="panel-body">
          <a href="" className="list-group-item">
            <i className="fa fa-undo" aria-hidden="true"></i>
            Order history
          </a>
          <a href="" className="list-group-item">
            <i className="fa fa-cloud-download" aria-hidden="true"></i>
            Downloads
          </a>
          <a href="" className="list-group-item">
            <i className="fa fa-usd" aria-hidden="true"></i>
            Recurring payment
          </a>
          <a href="" className="list-group-item">
            <i className="fa fa-shield" aria-hidden="true"></i>
            Reward points
          </a>
          <a href="" className="list-group-item">
            <i className="fa fa-retweet" aria-hidden="true"></i>
            Returns
          </a>
          <a href="" className="list-group-item">
            <i className="fa fa-money" aria-hidden="true"></i>
            Transactions
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Myaccount);
