import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FallingLines } from 'react-loader-spinner';


import "./index.css";

const Faqs = () => {
  const [faqs, setFaqs] = useState([]);
  const sectionRef = useRef(null)

  useEffect(() => {
    // fetch("https://prostuti-backend.techknowgram.net/api/react/events")
    //     .then((response) => response.json())
    //     .then((data) => setEvents(data));

    const url = "https://prostuti-backend.techknowgram.net/api/react/faqs";

    axios
      .get(url)
      .then(function (response) {
        console.log(response);
        const { data } = response;
        setFaqs(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  console.log("faqs", faqs);
  useEffect(() => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' })
  
  }, [])
  

  return (
    <>
      <section id="banner" class="Banner_others section_gaps faq">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>FAQ</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="rules" class="section_gaps" ref={sectionRef}>
        <div class="container">
          <div class="row">
            <div class="col-lg-8 m-auto">
              <div class="rules_content faq_content">
                <h3>FAQ</h3>

                {faqs && faqs.length ? (
                  faqs.map((item, i) => (
                    <p class="py-2 fw-bold">
                      <p class="paragraph">
                        <h5>
                          <b>Q. {item.que} </b>
                        </h5>
                      </p>
                      <p class="py-2">
                        <h5>
                          <b>A.</b> {item.ans}
                        </h5>
                      </p>
                    </p>
                  ))
                ) : (
                  <FallingLines
                    color="#4fa94d"
                    width="100"
                    visible={true}
                    ariaLabel='falling-lines-loading'
                  />
                )}

                {/* <p class="py-4 fw-bold">Q. QuizWin কি? </p>
                            <p class="py-4">A. একটি শিক্ষামূলক গেমিং প্লাটফর্ম । এখানে গেম খেলার জন্য একজন ব্যাক্তিকে একটি নির্দিষ্ট সময়ের মধ্যে বিভিন্ন প্রশ্নের উত্তর দিতে হয় এবং ভুল হলে সঠিক উত্তর জানিয়ে দেওয়া হয়। যার মাধ্যমে যে কেউ খুব সহজেই নিজের জ্ঞানের পরিধি বৃদ্ধি করতে পারে এবং বিভিন্ন প্রতিযোগীতামূলক পরীক্ষার জন্য নিজেকে প্রস্তুত করতে পারে। আর প্রতিদিন বিজয়ী হয়ে আকর্ষণীয় পুরষ্কার জিতে নেওয়ার সুযোগ তো থাকছেই।</p>

                            <p class="py-4 fw-bold">Q. কুইজে অংশগ্রহনের জন্য আমাকে কি কি করতে হবে ? </p>
                            <p class="py-4">A. প্রথমে কুইজবাজ এর নির্দিষ্ট ওয়েবসাইটে গিয়ে সঠিক তথ্য প্রদান করে রেজিস্ট্রেশন করে নিতে হবে । তারপর লগইন করে নিজের পছন্দ মত ইভেন্ট বা দৈনন্দিন কুইজ গুলোতে অংশগ্রহণ করে পরষ্কার জিতে নিতে পারবে।</p>

                            <p class="py-4 fw-bold">Q. QuizWin এর কুইজে অংশগ্রহন করে কি কি সুবিধা পাওয়া যাবে? </p>
                            <p class="py-4">A. একটি নির্দিষ্ট সময়ের মধ্যে এম সি কিউ পরীক্ষা দেয়ার সক্ষমতা অর্জন করা যায়, বিভিন্ন বিষয়ের উপর কুইজ খেলার মাধ্যমে নিজের জ্ঞানের পরিধি যাচাই ও বৃদ্ধি করা যায়, প্রতিযোগিতা মুলক পরীক্ষার জন্য নিজেকে প্রস্তুত করা যায়, প্রতি ইভেন্টে আকর্ষণীয় পুরষ্কারসহ প্রতিদিন পুরষ্কার জেতার সুযোগ।</p>

                            <p class="py-4 fw-bold">Q. একটি একাউন্ট দিয়ে কি একাধিক ইভেন্ট এ অংশগ্রহন করতে পারবো? </p>
                            <p class="py-4">A. হ্যা।</p>

                            <p class="py-4 fw-bold">Q. একই একাউন্ট দিয়ে কি প্রতিদিন কুইজে অংশগ্রহন করতে পারবো? </p>
                            <p class="py-4">A. হ্যা।</p>

                            <p class="py-4 fw-bold">Q. একটি একাউন্ট দিয়ে কি একটি ইভেন্ট এ একাধিক বার অংশগ্রহন করতে পারবো? </p>
                            <p class="py-4">A. না।</p>

                            <p class="py-4 fw-bold">Q. কুইজে অংশগ্রহনের জন্য কিভাবে পয়েন্ট সংগ্রহ করবো? </p>
                            <p class="py-4">A. “পয়েন্ট সংগ্রহ করুন” বাটনে ক্লিক করে নির্দিষ্ট প্রক্রিয়া অনুসরন পূর্বক পয়েন্ট সংগ্রহ করতে পারবে।</p>

                            <p class="py-4 fw-bold">Q. বিজয়ী হওয়ার পর কিভাবে পুরষ্কার আমার কাছে পৌছাবে? </p>
                            <p class="py-4">A. রেজিস্ট্রেশনে প্রদান করা নির্দিষ্ট ঠিকানায় পৌঁছে যাবে।</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;
