import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FallingLines } from 'react-loader-spinner';


const Rule = () => {
  const [rules, setRules] = useState([]);
  const sectionRef = useRef(null)

  useEffect(() => {
    // fetch("https://prostuti-backend.techknowgram.net/api/react/events")
    //     .then((response) => response.json())
    //     .then((data) => setEvents(data));

    const url = "https://prostuti-backend.techknowgram.net/api/react/rules";

    axios
      .get(url)
      .then(function (response) {
        console.log(response);
        const { data } = response;
        setRules(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  console.log("rules", rules);
  useEffect(() => {
    
    sectionRef.current.scrollIntoView({ behavior: 'smooth' })
  
    }
  , [])
  

  return (
    <>
      <section id="banner" class="Banner_others section_gaps">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>নিয়মাবলী</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="rules" class="section_gaps" ref={sectionRef}>
        <div class="container">
          <div class="row">
            <div class="col-lg-8 m-auto">
              <div class="rules_content">
                <h3>নিয়মাবলি</h3>
                <ul>
                  {/* @foreach($events as $event)
                                        <li class="nextStep" data-id="{{ $event->id }}">
                                            <a href="#">  {{ $event-> event_name}}  </a>
                                        </li>
                                        @endforeach */}

                  {rules && rules.length ? (
                    rules.map((item, i) => (
                      <li
                        class={`nextStep ${i == 0 ? "current" : ""}`}
                        data-id={item.id}
                      >
                        <b> {item.rule} </b>
                      </li>
                    ))
                  ) : (
                    <FallingLines
                      color="#4fa94d"
                      width="100"
                      visible={true}
                      ariaLabel='falling-lines-loading'
                    />
                  )}

                  {/* <li>কুইজে অংশগ্রহনের জন্য প্রত্যেক প্রতিযোগীকে রেজিস্ট্রেশনের মাধ্যমে একটি স্থায়ী একাউন্ট খুলে নিতে হবে।</li>
                                    <li>প্রত্যেক ইভেন্ট এবং কুইজে অংশগ্রহন করার পূর্বে অবশ্যই সঠিক ইউজার আইডি ও পাসওয়ার্ড দিয়ে লগইন করে নিতে হবে।</li>
                                    <li>একটি একাউন্টের মাধ্যমে একজন প্রতিযোগী কুইজউইন এর বিভিন্ন ইভেন্ট এবং কুইজ এ অংশগ্রহন করতে পারবে কিন্তু একই একাউন্টের মাধ্যমে একটি নির্দিষ্ট ইভেন্ট বা কুইজ এ একাধিকবার অংশগ্রহণ করতে পারবে না</li>
                                    <li>রেজিস্ট্রেশন করার সময় প্রত্যেক প্রতিযোগীকে সঠিক নাম, ঠিকানা, ছবি, ফোন নম্বর এবং ইমেইল আইডি ব্যাবহার করতে হবে।</li>
                                    <li>প্রতি সঠিক উত্তরের মান ১ এবং প্রতি ভুল উত্তরের জন্য .২৫ করে নম্বর কাটা যাবে।</li>
                                    <li>পরবর্তীতে বিজয়ীদের সাথে যোগাযোগ করে তাদের পছন্দের প্লেয়ার এডিশন জার্সি প্রদান করা হবে।</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Rule;
