import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FallingLines } from "react-loader-spinner";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { authOperations } from "../../state/ducs/auth";
import { authHeader } from "../../utils";
import "./index.css";

import { Pencil } from "akar-icons";

const MySwal = withReactContent(Swal);

const Profile = ({ userInfo, checkUser }) => {
  const navigate = useNavigate();
  const [played, setPlayed] = useState([]);
  const [retrieving, setRetrieving] = useState(false);

  const myButtonRef = useRef(null);

  useEffect(() => {
    setRetrieving(true);
    const url =
      "https://prostuti-backend.techknowgram.net/api/react/user/playedEvents";

    axios
      .get(url, { headers: authHeader() })
      .then(function (response) {
        const { data } = response;
        setPlayed(data);
        setRetrieving(false);
      })
      .catch(function (error) {});
  }, []);

  const goToVerify = (e) => {
    const url =
      "https://prostuti-backend.techknowgram.net/api/react/sendVerificationLink";

    axios
      .get(url, { headers: authHeader() })
      .then(function (response) {
        const { data } = response;
        if (response.status === 200) {
          MySwal.fire(
            <p>{data.message}, please check your email (spam, junk etc.)</p>
          );
        }
      })
      .catch(function (error) {});
  };

  const goToEdit = () => {
    navigate("/edit");
  };

  const goToChallenge=(event_id)=> {
    navigate("/challenge/"+event_id);
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("profile_photo", file);

    // Make AJAX request to upload the file
    const url = "https://prostuti-backend.techknowgram.net/api/react/user/uploadProfilePhoto";
    axios.post(url, formData, { headers: authHeader() }).then((response) => {
      console.log(response.data);
      checkUser();
      MySwal.fire(<p>Picture Update Successful</p>);
    }).catch((error) => {
      if (error.response.data.message.length) {
        MySwal.fire(<p style={{color: "red"}}>{error.response.data.message}</p>);
      }
    });;
  };


  const fileHandler = (e) => {
    myButtonRef.current.click();
  }

  return (
    <>
      <section id="banner" class="Banner_others section_gaps">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>Profile information</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="picture" class="section_gaps" style={{ paddingBottom: "0px" }}>
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-8 m-auto">

              <div onClick={fileHandler} class="imgleft d-flex justify-content-center align-items-center">

                {userInfo.full_user_photo_path ? (
                  <img src={userInfo.full_user_photo_path} style={{ height: "100%", width: "100%" }} class="img-thumbnail" alt="profile Picture"></img>
                ) : (
                  <span>
                    <i className="fa fa-user" style={{ fontSize: "70px" }}></i>
                  </span>
                )}

                <div className="right">
                  <input ref={myButtonRef} id="myFileInput" type="file" style={{ display: "none" }} onChange={handleFileUpload} />
                </div>
                <div className="overlay">
                  <h4>Change Picture</h4>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      <section id="rules" class="section_gaps">
        <div class="container">
          <div class="row">
            <div class="col-md-4"></div>
          </div>
          <div class="row">
            <div class="col-lg-8 m-auto">
              <div class="rules_content">
                <h3>
                  Profile information{" "}
                  <span style={{ cursor: "pointer" }}>
                    <Pencil color="#61dafb" size={30} onClick={goToEdit} />
                  </span>
                </h3>
                <ul>
                  <li>Username : {userInfo.username}</li>
                  <li>Name : {userInfo.name}</li>
                  <li>Phone : {userInfo.mobile_number}</li>
                  <li>
                    Email : {userInfo.email}{" "}
                    {userInfo.email_verified_at ? (
                      <>
                        <button className="verified">Verified</button>{" "}
                      </>
                    ) : (
                      <>
                        <button onClick={goToVerify} className="verify">
                          Verify
                        </button>{" "}
                      </>
                    )}
                  </li>
                  <li>Coin : {userInfo.balance}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container my-2">
          <div class="row">
            <div class="col-lg-8 m-auto">
              <div class="rules_content">
                <h3
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    color: "#0078cf",
                    fontWeight: "200",
                  }}
                >
                  Played Events
                </h3>

                {played.length ? (
                  <div className="table-responsive">
                  <table
                    className="table table-striped"
                    style={{
                      // backgroundColor: "#f5eee6",
                      color: "#0078cf",
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">Event Name</th>
                        <th scope="col">Position</th>
                        <th scope="col">Total Mark</th>
                        <th scope="col">Total Time</th>
                        <th scope="col">Date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {played.map((item) => (
                      <tbody>
                        <tr>
                          <td> {item.name} </td>
                          <td> {item.position} </td>
                          <td> {item.total_mark} </td>
                          <td> {item.total_time} </td>
                          <td> {item.played_on.split("T")[0]} </td>
                          <td><button onClick={()=>goToChallenge(item.user_quiz_answer_id)} className="btn btn-sm btn-success">View</button></td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                  </div>
                ) : (
                  ""
                )}

                {!played.length && retrieving ? (
                  <FallingLines
                    color="#4fa94d"
                    width="100"
                    visible={true}
                    ariaLabel="falling-lines-loading"
                  />
                ) : (
                  ""
                )}
                {!played.length && !retrieving ? (
                  <h4 className="text-center">No Played Events to Show</h4>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};


const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    userInfo: state.auth.user,
  };
};

const mapDispatchToProps = {
  checkUser: authOperations.authUserCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
